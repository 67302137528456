import Vue from 'vue'
import Router from 'vue-router'
import Index from '@/components/index'
import Edite from '@/components/edite'
import TableToday from '@/components/tableToday'
import TableTodayNew from '@/components/tableTodayNew'
import TableTomorrow from '@/components/tableTomorrow'
import TableTomorrowNew from '@/components/tableTomorrowNew'
import PhoneToday from '@/components/phone-today'
import PhoneTomorrow from '@/components/phone-tomorrow'
import Tomorrow from '@/components/tomorrow'
import Today from '@/components/today'
import TodayCrawler from '@/components/today-crawler'
import TomorrowCrawler from '@/components/tomorrow-crawler'
import Login from '@/components/login'
import Checkback from '@/components/checkback'
import CheckbackTomorrow from '@/components/checkback-tomorrow'
import CheckbackM from '@/components/checkback-m'
import CheckbackList from '@/components/checkback-list'
import CheckbackListDetail from '@/components/checkback-list-detail'
import CheckbackTomorrowM from '@/components/checkback-tomorrow-m'
import GetCheckbackTomorrow from '@/components/get-checkback-tomorrow'
import GetCheckback from '@/components/get-checkback'
import Fav from '@/components/fav'
import ToolsQsdaToHandicap from '@/components/toolsQsdaToHandicap'
import ToolsCheckback from '@/components/toolsCheckback'
import TaiKuang from '@/components/taikuang'
import Charts from '@/components/comment/charts'
import EditTaiKuang from '../components/edite/edite-taikuang.vue'
import EditLeague from '../components/edite/edite-league.vue'
import EditTeam from '../components/edite/edite-team.vue'
import { checkLoginStatus,refreshToken } from '@/user/auth'
Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
      {
          path: '/',
          name: 'index',
          component: Index,
          meta: {
            title: '蓝理论足球',
            requiresAuth: true // 需要验证登录
          }
      },
      {
        path: '/login',
        name: 'login',
        component: Login,
          meta: {
            title: '用户登录',
          }
      },
      {
          path: '/edite',
          name: 'edite',
          component: Edite,
          meta: {
            title: '编辑',
            requiresAuth: true // 需要验证登录
          }
      },
      {
          path: '/qsda-today',
          name: 'qsda-today',
          component: TableToday,
          meta: {
            title: '今日广实qsda（pc端）',
            requiresAuth: true // 需要验证登录
          }
      },
      {
          path: '/qsda-today-new',
          name: 'qsda-today-new',
          component: TableTodayNew,
          meta: {
            title: '今日广实qsda（pc端）',
            requiresAuth: true // 需要验证登录
          }
      },
      {
          path: '/qsda-today-new/:date',
          name: 'qsda-today-new',
          component: TableTodayNew,
          meta: {
            title: '今日广实qsda（pc端）',
            requiresAuth: true // 需要验证登录
          }
      },
      {
          path: '/qsda-today/:date',
          name: 'qsda-today',
          component: TableToday,
          meta: {
            title: '广实qsda（pc端）',
            requiresAuth: true // 需要验证登录
          }
      },
      {
        path: '/qsda-tomorrow',
        name: 'qsda-tomorrow',
        component: TableTomorrow,
        meta: {
          title: '明日广实qsda（pc端）',
          requiresAuth: true // 需要验证登录
        }
      },
      {
        path: '/qsda-tomorrow-new',
        name: 'qsda-tomorrow-new',
        component: TableTomorrowNew,
        meta: {
          title: '明日广实qsda（pc端）',
          requiresAuth: true // 需要验证登录
        }
      },
      {
          path: '/qsda-today-m',
          name: 'qsda-today-m',
          component: PhoneToday,
          meta: {
            title: '广实qsda(手机端)',
            requiresAuth: true // 需要验证登录
          }
      },
      {
          path: '/qsda-today-m/:date',
          name: 'qsda-today-m',
          component: PhoneToday,
          meta: {
            title: '广实qsda(手机端)',
            requiresAuth: true // 需要验证登录
          }
      },
      {
          path: '/qsda-tomorrow-m',
          name: 'qsda-tomorrow-m',
          component: PhoneTomorrow,
          meta: {
            title: '广实qsda(手机端)',
            requiresAuth: true // 需要验证登录
          }
      },
      {
          path: '/tomorrow',
          name: 'tomorrow',
          component: Tomorrow,
          meta: {
            title: '抓取明日数据',
            requiresAuth: true // 需要验证登录
          }
      },
      {
          path: '/today',
          name: 'today',
          component: Today,
          meta: {
            title: '抓取今日数据',
            requiresAuth: true // 需要验证登录
          }
      },
      {
          path: '/today-crawler',
          name: 'today-crawler',
          component: TodayCrawler,
          meta: {
            title: '抓取今日数据',
            requiresAuth: true // 需要验证登录
          }
      },
      {
          path: '/tomorrow-crawler',
          name: 'tomorrow-crawler',
          component: TomorrowCrawler,
          meta: {
            title: '抓取明日数据',
            requiresAuth: true // 需要验证登录
          }
      },
      {
        path: '/checkback',
        name: 'checkback',
        component: Checkback,
        meta: {
          title: '大数据回查',
          requiresAuth: true // 需要验证登录
        }
      },
      {
        path: '/checkback-t',
        name: 'checkback-t',
        component: CheckbackTomorrow,
        meta: {
          title: '明日大数据回查',
          requiresAuth: true // 需要验证登录
        }
      },
      {
        path: '/checkback-tomorrow-m',
        name: 'checkback-tomorrow-m',
        component: CheckbackTomorrowM,
        meta: {
          title: '明日大数据回查',
          requiresAuth: true // 需要验证登录
        }
      },
      {
        path: '/checkback-m',
        name: 'checkback-m',
        component: CheckbackM,
        meta: {
          title: '大数据回查',
          requiresAuth: true // 需要验证登录
        }
      },
      {
        path: '/checkback-list',
        name: 'checkback-list',
        component: CheckbackList,
        meta: {
          title: '回查列表',
          requiresAuth: true // 需要验证登录
        }
      },
      {
        path: '/checkback-list-detail/:id',
        name: 'checkback-list-detail',
        component: CheckbackListDetail,
        meta: {
          title: '回查详情',
          requiresAuth: true // 需要验证登录
        }
      },
      {
        path: '/getCheckback',
        name: 'getCheckback',
        component: GetCheckback,
        meta: {
          title: '回查数据抓取',
          requiresAuth: true // 需要验证登录
        }
      },
      {
        path: '/getCheckbackTomorrow',
        name: 'getCheckbackTomorrow',
        component: GetCheckbackTomorrow,
        meta: {
          title: '明日回查数据抓取',
          requiresAuth: true // 需要验证登录
        }
      },
      {
        path: '/fav',
        name: 'fav',
        component: Fav,
        meta: {
          title: '收藏',
          requiresAuth: true // 需要验证登录
        }
      },
      {
        path: '/tools-qsda-to-handicap',
        name: 'tools-qsda-to-handicap',
        component: ToolsQsdaToHandicap,
        meta: {
          title: 'qsda转为盘口',
          requiresAuth: true // 需要验证登录
        }
      },
      {
        path: '/tools-checkback',
        name: 'tools-checkback',
        component: ToolsCheckback,
        meta: {
          title: 'tools-checkback',
          requiresAuth: true // 需要验证登录
        }
      },
      {
        path: '/tools-taikuang',
        name: 'tools-taikuang',
        component: TaiKuang,
        meta: {
          title: 'tools-taikuang',
          requiresAuth: true // 需要验证登录
        }
      },
      {
        path: '/charts/:id',
        name: 'charts',
        component: Charts,
        meta: {
          title: '离散度',
          requiresAuth: true // 需要验证登录
        }
      },
      {
        path: '/edite-taikuang',
        name: 'edite-taikuang',
        component: EditTaiKuang,
        meta: {
          title: '修改态况数据',
          requiresAuth: true // 需要验证登录
        }
      },
      {
        path: '/edite-league-name',
        name: 'edite-league-name',
        component: EditLeague,
        meta: {
          title: '修改球队名',
          requiresAuth: true // 需要验证登录
        }
      },
      {
        path: '/edite-team-name',
        name: 'edite-team-name',
        component: EditTeam,
        meta: {
          title: '修改球队名',
          requiresAuth: true // 需要验证登录
        }
      },
  ]
})

// 在这里进行登录状态验证逻辑
// 检查用户是否已登录或具有访问权限
// 根据验证结果决定是否允许导航到目标路由
router.beforeEach((to,from,next)=>{
  if (to.meta.title) { // 判断是否有标题
    document.title = to.meta.title;
  }
    if (to.meta.requiresAuth && !checkLoginStatus()) {
      console.log('需要登录但用户未登录');
      // 需要登录但用户未登录
      next('/login'); // 跳转到登录页
    }else{
      //若已经登录，检查24小时内是否刷新过token
      refreshToken()
      // 允许导航到目标路由
      next();
      if (from.name !== null) {
        window.location.reload();
      }
    }
    if (checkLoginStatus() && to.name === 'login') {
      // 如果用户已经登录并且要跳转的页面是登录页，那么重定向到首页
      next({ path: '/' });
    }
  })

export default router;